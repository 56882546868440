import React, {useEffect, useState} from 'react';
import {COOKIES_TYPES, CookiesService, loggedUser} from '@bri/shared-components';
import {useRecoilState} from 'recoil';
import {Link} from 'react-router-dom';
import {Feather, FontAwesome, Ionicons, MaterialIcons} from '@native-base/icons';
import {Flex, Hidden, Icon, Image, Link as NBLink, Pressable, ScrollView, useTheme, View, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import './Navbar.css';
import {USER_ACTIONS} from '@bri/galileus-core';
import jwt from 'jwt-decode'; // import dependency
import {ImageStyle, StyleProp} from 'react-native';
import NavbarItem from './NavbarItem';
import {getResource} from '../../utils/imagesUtils';
import {CurrentTheme} from '../../theme';

const MENU_WIDTH = 250;
export const MENU_ITEM_WIDTH = 160;

function Navbar() {
  const {t} = useTranslation();
  const theme = useTheme();
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const [user, setUser] = useRecoilState(loggedUser);
  const [menu, setMenu] = useState<any[]>([]);

  const SidebarData: {title: string; path: string; cName: string; icon: string; iconFamily: any; action?: USER_ACTIONS; onClick?: () => void}[] = [
    {
      title: t('Pasaportes de producto'),
      path: '/',
      cName: 'nav-text',
      icon: 'list',
      iconFamily: Feather,
      action: USER_ACTIONS.USE_APP,
    },
  ];

  if (user) {
    SidebarData.push({
      title: t('Salir'),
      path: '/',
      cName: 'nav-text',
      icon: 'logout',
      iconFamily: MaterialIcons,
      onClick: async () => {
        await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'access_token');
        await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'refresh_token');
        setUser(null);
      },
    });
  }

  const fetchData = async () => {
    const token = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'access_token');
    if (token && user) {
      const token_decoded = jwt(token); // decode your token here
      const actions: string[] = (token_decoded as any).aud.split(' ');

      // Pilla les actions del token
      setMenu(SidebarData.filter(item => item.action === undefined || actions.includes(`ACTION:${item.action}`)));
    } else {
      setMenu(SidebarData.filter(item => item.action === undefined));
    }
  };

  useEffect(() => {
    fetchData().catch(error => {
      console.error(error);
    });
  }, [user]);

  if (menu.length === 0) {
    return null;
  }

  return (
    <>
      <div className="navbar" style={{backgroundColor: theme.colors.primary[500], height: 60}}>
        <Link to="#" className="navbar-link">
          <Icon
            as={Ionicons}
            name="menu"
            size={6}
            color="secondary.500"
            onPress={event => {
              event.preventDefault();
              showSidebar();
            }}
          />
        </Link>
        <Image source={getResource(CurrentTheme.images.logoLeft)} width={132} h={45} ml={2} resizeMode="contain" />
        <Flex style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', paddingVertical: 15, paddingRight: 55}}>
          <Hidden till="sm">
            <>
              {CurrentTheme.images.logoRight1 && (
                <Image
                  source={getResource(CurrentTheme.images.logoRight1)}
                  width={129}
                  h={30}
                  resizeMode="contain"
                  style={{filter: 'brightness(0) invert(1)', marginRight: 0} as StyleProp<ImageStyle>}
                />
              )}
              {CurrentTheme.images.logoRight2 && (
                <Image
                  source={getResource(CurrentTheme.images.logoRight2)}
                  width={77}
                  h={30}
                  ml={7}
                  resizeMode="contain"
                  style={{filter: 'brightness(0) invert(1)'} as StyleProp<ImageStyle>}
                />
              )}
            </>
          </Hidden>
        </Flex>
      </div>
      <View
        // className={sidebar || windowWidth >= 1009 ? 'nav-menu active' : 'nav-menu'}
        style={[
          {
            width: MENU_WIDTH,
            height: '100vh',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed' as any,
            top: 0,
            left: '-100%',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            transition: '850ms',
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sidebar && {left: 0, transition: '450ms'},
        ]}
        backgroundColor="coolGray.100">
        <VStack style={{width: '100%'}} flex={1} space={5} borderRightWidth={2} borderRightColor="primary.500" shadow={5}>
          <Pressable style={{height: 80, justifyContent: 'center', alignItems: 'flex-start'}} onPress={showSidebar}>
            <Link to="#" className="navbar-link">
              <Icon as={FontAwesome} name="close" size={6} color="primary.500" />
            </Link>
          </Pressable>
          <ScrollView flex={1} contentContainerStyle={{flexGrow: 1, justifyContent: 'space-between'}}>
            <View>
              {menu.map((item, index) => (
                <NavbarItem
                  key={index}
                  item={item}
                  onPress={() => {
                    showSidebar();
                    item.onClick && item.onClick();
                  }}
                />
              ))}
            </View>
            <VStack mb={4} mx={4} space={2}>
              <Link to="cookies-config" style={{textDecoration: 'none'}} onClick={showSidebar}>
                <NBLink
                  isUnderlined={false}
                  _text={{
                    color: 'primary.500',
                  }}>
                  {t('Preferencias de cookies')}
                </NBLink>
              </Link>
              <Link to="cookies" style={{textDecoration: 'none'}} onClick={showSidebar}>
                <NBLink
                  isUnderlined={false}
                  _text={{
                    color: 'primary.500',
                  }}>
                  {t('Política de cookies')}
                </NBLink>
              </Link>
              <Link to="termsAndConditions" style={{textDecoration: 'none'}} onClick={showSidebar}>
                <NBLink
                  isUnderlined={false}
                  _text={{
                    color: 'primary.500',
                  }}>
                  {t('Términos y condiciones')}
                </NBLink>
              </Link>
              <Link to="privacyPolicy" style={{textDecoration: 'none'}}>
                <NBLink
                  isUnderlined={false}
                  _text={{
                    color: 'primary.500',
                  }}>
                  {t('Política privacidad')}
                </NBLink>
              </Link>
            </VStack>
          </ScrollView>
        </VStack>
      </View>
    </>
  );
}

export default Navbar;
