/* eslint-disable global-require */

export const ProjectTheme = {
  login: {
    backgroundUrl: '/login_background_3.jpg',
    logo: {file: '/BRILogo.png', width: 200, height: 130},
  },
  images: {
    logoLeft: require('./images/BRILogo-white.png'),
    logoRight1: null,
    logoRight2: null,
    information: require('./images/tuberia-industrial.jpg'),
    materials: require('./images/tuberia-industrial.jpg'),
    production: require('./images/tuberia-industrial.jpg'),
    distribution: require('./images/tuberia-industrial.jpg'),
  },
  theme: {
    colors: {
      primary: {
        50: '#eff5ff',
        100: '#dce7fd',
        200: '#c0d6fd',
        300: '#95bdfb',
        400: '#6399f7',
        500: '#3f74f2',
        600: '#325ce8',
        700: '#2141d4',
        800: '#2137ac',
        900: '#203288',
        950: '#182153',
      },
      secondary: {
        50: '#F7FCFE',
        100: '#E4F4FD',
        200: '#D1ECFC',
        300: '#BEDEFB',
        400: '#A6CDF9',
        500: '#C3E3F6',
        600: '#89B0EF',
        700: '#7592E6',
        800: '#6174DD',
        900: '#4D56D4',
      },
    },
  },
  title: 'Digital Product Passport', // Tab title
  favicon: '/BRILogo.png',
};

const theme = ProjectTheme;

export const CurrentTheme = theme;
