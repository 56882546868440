import {PrivacyPolicy} from '@bri/shared-components';
import {PageLayout} from '../components/PageLayout';

export default function PrivacyPolicyPage() {
  return (
    <PageLayout>
      <PrivacyPolicy
        titleFontSize="2xl"
        company={{
          name: 'Blue Room Innovation SL.',
          address: 'Gran Vía Carles III, 98 08028, Barcelona',
          contactEmail: 'info@blueroominnovation.com',
          id: 'B55237689',
        }}
        projectName="galileus"
        appOrUrl="galileus"
      />
    </PageLayout>
  );
}
