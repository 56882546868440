import {FC, useEffect, useState} from 'react';
import {Button, View, useBreakpointValue} from 'native-base';
import {useTranslation} from 'react-i18next';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {GenericModal, Props as BaseProps} from './GenericModal';
import ProductData from './ProductData';
import {useProductPassportsService} from '../services/ProductPassportsService';
import {ParsedProduct} from '../types/types';
import {Mapping} from '../utils/fieldMapping';

type Props = Pick<BaseProps, 'isOpen' | 'onClose'> & {
  id?: string;
};

const ProductInformationModal: FC<Props> = ({id, isOpen, onClose}) => {
  const {t} = useTranslation();
  const sharedToast = useSharedToast();
  const isModalVertical: boolean = useBreakpointValue({base: true, lg: false});

  const productPassportService = useProductPassportsService();
  const [product, setProduct] = useState<ParsedProduct>();

  useEffect(() => {
    if (!id) {
      return;
    }

    productPassportService
      .getById({idPassport: id})
      .response(response => setProduct(Mapping(response)))
      .error(() => {
        sharedToast({
          title: t('Algo ha ido mal'),
          description: t('No se ha podido obtener la información del material trazable seleccionado'),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
          isClosable: true,
        });

        onClose();
      });
  }, [id]);

  if (!id) {
    return null;
  }

  return (
    <GenericModal
      title={product?.name}
      isOpen={isOpen}
      onClose={onClose}
      heightPercent={isModalVertical ? 0.9 : 0.85}
      widthPercent={isModalVertical ? 0.95 : 0.75}
      padding={isModalVertical ? {sides: '30px', top: '20px', bottom: '10px'} : {sides: '40px', top: '40px', bottom: '5px'}}
      minHeight="0px">
      <View py={5} pb={10}>
        <ProductData passportProduct={(product as unknown) as Partial<ParsedProduct>} id={product?._id} showTitle={false} showSummarySheets={false} />
        <Button
          alignSelf="center"
          w="60%"
          onPress={() => {
            window.location.href = `/passport/${product?._id}`;
          }}>
          {t('Ver pasaporte entero')}
        </Button>
      </View>
    </GenericModal>
  );
};

export default ProductInformationModal;
