import {ProductPassportsClientServiceTypes} from '@bri/galileus-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export function useProductPassportsService() {
  const baseService = useBaseService();

  const productPassportServiceFunctions: ServiceFunctionsType<ProductPassportsClientServiceTypes> = {
    list: params => baseService().get('/v1/product/passports/list', params),
    getById: params =>
      baseService()
        .auth()
        .get('/v1/product/passports/getById', params),
    getEvents: params =>
      baseService()
        .auth()
        .get('/v1/product/passports/getEvents', params),
    addRemote: params =>
      baseService()
        .auth()
        .post('/v1/product/passports/addRemote', params),
  };

  return productPassportServiceFunctions;
}
